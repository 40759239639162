import ReactDOM from 'react-dom/client';
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Data
import NavarroCity from './NavarroCity.json'

// Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: "AIzaSyCjiH_a5SzV4wAkHrfQIBCohRravdn-VOQ",
  authDomain: "navarrocity-react.firebaseapp.com",
  projectId: "navarrocity-react",
  storageBucket: "navarrocity-react.appspot.com",
  messagingSenderId: "157799832512",
  appId: "1:157799832512:web:2267415b98ba21ec454625",
  measurementId: "G-6Y2X7T5LZH"
});
getAnalytics(firebaseApp);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                { Object.keys(NavarroCity.links).map(key => <Route key={key} path={'/' + key} element={<Redirect to={NavarroCity.links[key]}/>} />) }
                <Route path="*" element={<Redirect to="https://www.navarrotech.net/about" replace={true}/>} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

function Redirect({ to }){
    // if(window.location.origin.includes('localhost')){ return <div></div>; }
    window.location.replace(to); return <></>;
}